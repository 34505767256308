<script setup>
import { computed, onMounted, ref } from 'vue';
import Spinner from './components/spinner';
import PublicLayout from './layouts/public-layout.vue';
import PrivateLayout from './layouts/private-layout.vue';
import BlankLayout from './layouts/blank-layout.vue';
import NotificationRibbon from './components/notification-ribbon/notification-ribbon.vue';
import ModalAsync from './components/modal-async/modal-async-loader.vue';
import { initializeConfiguration } from './services/config.service';
import { checkSession, getters as authGetters } from './services/auth.service';
import { useRoute, useRouter } from 'vue-router';
import getRedirectRoute from './core/router.auth';
import {
    getSubscriptionForLoggedInUser,
    getSubscriptionTypeForLoggedInUser,
} from './services/subscription/subscription.service.js';
import { getFeatureFlags } from './services/tenant-settings/tenant-settings.service.js';
import { get } from './services/local-store/index.js';

const appIsLoaded = ref(false);

const route = useRoute();
const router = useRouter();

const layout = computed(() => {
    if (route.meta?.isBlankLayout) {
        return BlankLayout;
    }

    if (
        authGetters.isAuthenticated &&
        !route.meta?.hybrid &&
        !route.meta?.public
    ) {
        return PrivateLayout;
    }

    return route.meta?.layout || PublicLayout;
});

onMounted(async () => {
    try {
        await initializeConfiguration();

        await Promise.all([getFeatureFlags(), checkSession()]);

        if (!authGetters.isTenantLevelUser && !get('ADHOC_USER_JWT_DATA')) {
            await Promise.all([
                getSubscriptionTypeForLoggedInUser(),
                getSubscriptionForLoggedInUser(),
            ]);
        }
    } catch (error) {
        console.error(error);
    } finally {
        const nextRoute = getRedirectRoute(route);

        await router.push(nextRoute);

        appIsLoaded.value = true;
    }
});
</script>

<template>
    <div
        ref="app"
        class="evo-flex evo-flex-col evo-w-full evo-h-full evo-min-h-screen"
    >
        <spinner
            v-if="!appIsLoaded"
            class="evo-absolute evo-top-0 evo-left-0 evo-w-full evo-h-full evo-z-20"
        ></spinner>
        <component :is="layout" v-else>
            <router-view></router-view>
        </component>

        <modal-async></modal-async>
        <notification-ribbon></notification-ribbon>
    </div>
</template>
