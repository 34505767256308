import apiClient from '../../core/api/api-client';
import { API_ROUTES } from '../../core/constants';

/**
 * Adds a property to each language containing the country short code
 * @param {Array} data
 * @returns {Array}
 */
function addShortCodeKey(data) {
    return data.map((item) => {
        return {
            ...item,
            shortCode: item.tag && item.tag.split('-')[0].toUpperCase(),
        };
    });
}

/**
 * Returns an array of all available languages
 * @returns {Promise}
 */
export const getPlatformLanguages = () =>
    apiClient.get(API_ROUTES.TENANTS.PLATFORM.LANGUAGES);

/**
 * Returns an array of tenant language objects consisting of tag and name properties.
 * @param {boolean} noAuth
 * @returns {Promise}
 */
export const getTenantLanguages = (noAuth = false) =>
    apiClient
        .get(API_ROUTES.TENANTS.TENANT_SETTINGS.LANGUAGE, {}, {}, noAuth)
        .then(addShortCodeKey);

/**
 * Sends an array of updated language tags to the server
 * @param {Array} data
 * @returns {Promise}
 */
export const updateTenantLanguages = (data) =>
    apiClient.put(
        API_ROUTES.TENANTS.TENANT_SETTINGS.UPDATE_LANGUAGE,
        data.map((item) => item.tag)
    );

/**
 * Fetch the okta translations based on the language received.
 * Using fetch instead of apiClient because this is in un-authenticated call
 * @param {string} language
 * @returns {Promise}
 */
export const loadOktaTranslations = (language = 'en-GB') =>
    fetch(
        `${
            EVERON_CDN_URL || '.'
        }/assets/translations/okta/${language}.okta.json`
    )
        .then((res) => res.json())
        .catch(() => null);

/**
 * This function is used to get the language from local storage, which
 * is set by Everon Portal, but can be re-used to set the language.
 *
 * @param {string} fallback - language code
 * @returns {string} - language code
 */
export function getLanguageFromLocalStorage(fallback = 'en-GB') {
    const localStoreLanguage = localStorage.getItem('NG_TRANSLATE_LANG_KEY');

    if (localStoreLanguage === 'undefined') {
        return fallback;
    }

    return localStoreLanguage || fallback;
}
